import styled from 'styled-components';

const StyledShareIcons = styled.div`
  cursor: pointer;
  margin-bottom: calc(2 * 0.5rem);
  div {
    display: inline-block;
    a {
      background-color: transparent;
      border: 2px solid #00ff00;
      border-radius: 50px;
      display: inline-block;
      height: 46px;
      margin-right: 6px;
      position: relative;
      width: 46px;

      svg {
        height: 18px;
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 18px;
        fill: #00ff00;
      }
    }
  }
`;

export default StyledShareIcons;
