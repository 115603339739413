import React from 'react';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';

import Header from '../components/header';
import Footer from '../components/footer';
import Share from '../components/share';
import DynamicComponent from '../components/dynamicComponent';
import Headline from '../components/headline';
import Layout from '../components/layout';

const JobPage = props => {
  const { job, globalInfo } = props.data;
  const { headline, parentPage } = job;
  const socialProfiles = props.data.socialProfiles.edges.map(item => item.node);
  const pages = props.data.pages
    ? props.data.pages.edges.filter(item => item.node.slug && item.node.title).map(item => item.node)
    : [];
  const sections = job.sections.filter(section => section.id && !section.id.includes('dummy'));
  const { locale } = props.pageContext;
  return (
    <Layout>
      <HelmetDatoCms seo={job.seoMetaTags} />
      {headline.title && <Headline title={headline.title.md.html} slug={headline.slug} locale={locale} />}
      <Header
        socialProfiles={socialProfiles}
        image={job.headerImage}
        title={job.titleAlias.md.html ? job.titleAlias.md.html : `<p>${job.title}</p>`}
        subTitle={job.subTitle.md.html}
        pages={pages}
        isSmall
        fullMenu
        backLabel={parentPage.shortTitle || 'Jobs'}
        backSlug={parentPage.slug || 'jobs'}
      />

      {/* Sharing */}
      <Share socialProfiles={socialProfiles} seo={job.seoMetaTags} />

      {/* Dynamic Sections */}
      {sections.map(section => (
        <DynamicComponent {...section} key={section.id} />
      ))}

      <Footer
        address={globalInfo.address}
        copyright={globalInfo.copyright}
        hasInternationalization={globalInfo.hasInternationalization}
        socials={socialProfiles}
        pages={pages}
        location={props.location}
      />
    </Layout>
  );
};

JobPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default JobPage;

export const query = graphql`
  query JobPageQuery($locale: String!, $jobId: String) {
    job: datoCmsJob(locale: { eq: $locale }, id: { eq: $jobId }) {
      parentPage {
        slug
        shortTitle
      }
      headline {
        title: titleNode {
          md: childMarkdownRemark {
            html
          }
        }
        slug
      }
      headerImage {
        sizes(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      title
      titleAlias: titleAliasNode {
        md: childMarkdownRemark {
          html
        }
      }
      subTitle: subTitleNode {
        md: childMarkdownRemark {
          html
        }
      }
      sections {
        __typename
        ...SingularContentFragment
        ...TripleColumnContentFragment
        ...MultipleIconContentFragment
        ...CallToActionLinkFragment
        ...QuoteLinkFragment
        ...ArticleLinksFragment
        ...BeneficiaryLinkFragment
        ...BannerFragment
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    globalInfo: datoCmsGlobalInfo {
      ...GlobalInfoFragment
    }
    socialProfiles: allDatoCmsSocialProfile(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...SocialProfileFragment
        }
      }
    }
    pages: allDatoCmsPage(filter: { locale: { eq: $locale }, showInMenu: { eq: true } }) {
      edges {
        node {
          ...PageFragment
        }
      }
    }
  }
`;
