import styled from 'styled-components';

const StyledShareText = styled.div`
  margin-bottom: calc(3 * 0.5rem);

  text-transform: uppercase;
  color: #a2a2a2;
  letter-spacing: 2px;
  font-size: 80%;
`;

export default StyledShareText;
