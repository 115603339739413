import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { fbFeed, tw, linkedin } from 'vanilla-sharing';

import LinkIcon from './linkIcon';

import StyledSection from '../style/common/styledSection';
import StyledSectionWrapper from '../style/common/styledSectionWrapper';
import StyledShareText from '../style/share/styledShareText';
import StyledShareIcons from '../style/share/styledShareIcons';

function shareFacebook(href) {
  const config = {
    url: href,
    redirectUri: href,
    hashtag: '#job',
    fbAppId: '610125692501162', // TODO: Should come from config: process.env.FB_APP_ID,
  };
  console.log('Share on Facebook', config);
  try {
    fbFeed(config);
  } catch (e) {
    console.error(e);
  }
}

function shareTwitter(url, title) {
  const config = {
    url,
    title,
  };
  console.log('Share on Twitter', config);
  tw(config);
}

function shareLinkedIn(url, title, description) {
  const config = {
    url,
    title,
    description,
  };
  console.log('Share on LinkedIn', config);
  linkedin(config);
}

class Share extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSeoTag(name) {
    let content = null;
    this.props.seo.tags.forEach(tag => {
      if (tag.tagName === name) {
        content = tag.content;
      } else if (tag.attributes && (tag.attributes.property === name || tag.attributes.name === name)) {
        content = tag.attributes.content;
      }
    });
    return content;
  }

  handleClick = (e, type) => {
    switch (type) {
      case 'Facebook':
        return shareFacebook(window.location.href);
      case 'Twitter':
        return shareTwitter(window.location.href, this.getSeoTag('twitter:description'));
      case 'LinkedIn':
        return shareLinkedIn(window.location.href, this.getSeoTag('title'), this.getSeoTag('description'));
      default:
        console.warn('Unknown sharing type');
        break;
    }
    e.preventDefault();
    return null;
  };

  render() {
    const { socialProfiles } = this.props;
    return (
      <StyledSectionWrapper>
        <StyledSection noVerticalPadding>
          <StyledShareText>
            <FormattedMessage id="share" />
          </StyledShareText>
          <StyledShareIcons>
            {socialProfiles.map(social => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <div
                key={social.profileType}
                onClick={e => this.handleClick(e, social.profileType)}
                role="button"
                tabIndex="0"
              >
                <LinkIcon key={social.profileType} svg={social.icon.inlineSvg} />
              </div>
            ))}
          </StyledShareIcons>
        </StyledSection>
      </StyledSectionWrapper>
    );
  }
}

Share.propTypes = {
  socialProfiles: PropTypes.array.isRequired,
  seo: PropTypes.object.isRequired,
};

export default Share;
